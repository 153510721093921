import Whisper from '../components/whisper'
import { renderComponent, unmountComponents } from '../helpers/shared_functions';
// Bootstrap
import { Tooltip, Toast, Popover } from 'bootstrap'

window.mountedComponents = []
window.whispers = () => {
  const whispers = document.querySelectorAll('input[data-whisper]')
  whispers.forEach( element => {
    if (!element.dataset.whisperMounted) {
      element.setAttribute('autocomplete','off')
      element.dataset.whisperMounted = true
      element.parentNode.classList.add('whisper-wrapper')
      let div = document.createElement('div')
      div.classList.add('whisper-container')
      element.parentNode.appendChild(div)
      let props = {input: element}
      if (element.dataset.props) {
        Object.assign(JSON.parse(element.dataset.props), {
          input: element
        })
      }

      renderComponent({
        element: div,
        component: Whisper,
        props
      })
    }
  })
}

const domReady = function() {
  window.whispers()
}

document.addEventListener('DOMContentLoaded', domReady)
window.addEventListener('unload', unmountComponents)

// Disable scroll on input type number
// jQuery(document).on("wheel", "input[type=number]", function (e) {
//   jQuery(this).blur()
// })
