import { createRoot } from 'react-dom/client'

export const renderComponent = ({ element, component, props }) => {
  if (!element || element.dataset.reactComponentMounted == 'true') return

  let root
  if (element.dataset.reactUUID && window.mountedComponents.find(i => i.uuid == element.dataset.reactUUID)) {
    root = window.mountedComponents.find(i => i.uuid == element.dataset.reactUUID).root
  } else {
    root = createRoot(element)
  }
  const uuid = getUUID()

  if (!props) {
    props = JSON.parse(element.dataset.props)
  }

  element.dataset.reactUUID = uuid

  root.render(
    React.createElement(component, props)
  )

  element.dataset.reactComponentMounted = 'true'
  
  window.mountedComponents ||= []
  window.mountedComponents.push({
    uuid,
    element,
    root
  })
}

export const unmountComponents = () => {
  window.mountedComponents.forEach((component) => {
    component.element.dataset.reactComponentMounted = 'false'
    try {
      component.root.unmount();
    } catch (error) {
      // do nothing
    }
  });

  window.mountedComponents = []
};

export const unmountComponentAtNode = (element) => {
  const uuid = element.dataset.reactUUID

  window.mountedComponents.forEach((component) => {
    component.element.dataset.reactComponentMounted = 'false'
    try {
      if (component.uuid == uuid) {
        component.root.unmount();
      }
    } catch (error) {
      // do nothing
    }
  });

  window.mountedComponents = window.mountedComponents.filter( i => i.uuid != uuid )
};

export const getUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  })
}