import { useState, useEffect, useRef } from 'react';
import {
  useFloating,
  autoUpdate,
  FloatingPortal,
  shift,
} from '@floating-ui/react';

function Whisper({ input, scenario, url }) {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [index, _setIndex] = useState(-1);
  const [fastSelect, setFastSelect] = useState(false);
  
  const currentRequest = useRef(null);
  const currentRequestTimeout = useRef(null);
  const resultsRef = useRef([])
  const indexRef = useRef(index)

  const setIndex = (data) => {
    indexRef.current = data
    _setIndex(data)
  }

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    // middleware: shift
  });

  useEffect(() => {
    if (fastSelect) {
      setFastSelect(false);
      performFastSelect();
    }

    resultsRef.current = results
  }, [results]);

  useEffect(() => {
    fetchResults();
  }, [query]);

  const focusOut = (e) => {
    setTimeout(() => {
      setQuery('');
      setIndex(-1);
    }, 200);
  };

  const keyUp = (e) => {
    switch (e.keyCode) {
      case 27:
        setQuery('');
        setIndex(-1);
        break;
      case 40:
        // Arrow Down
        if (resultsRef.current.length - 1 > index) setIndex(indexRef.current + 1);
        if (query == '') setQuery(input.value);
        break;
      case 38:
        // Arrow Up
        if (indexRef.current > 0) setIndex(indexRef.current - 1);
        break;
      case 13:
        // Enter
        if (resultsRef.current[indexRef.current]) {
          handleSelect(resultsRef.current[indexRef.current]);
          setQuery('');
          setIndex(-1);
          break;
        }

        // Fast SN select
        if (performFastSelect()) {
          break;
        }

      default:
        setQuery(input.value);
    }
  };

  const keyDown = (e) => {
    if (e.keyCode == 13) e.preventDefault();
  }

  useEffect(() => {
    input.addEventListener('focusout', focusOut);
    input.addEventListener('keyup', keyUp);
    input.addEventListener('keydown', keyDown);

    return () => {
      input.removeEventListener('focusout', focusOut);
      input.removeEventListener('keyup', keyUp);
      input.removeEventListener('keydown', keyDown);
    }
  }, []);

  const performFastSelect = (inSwitch = false) => {
    setFastSelect(true);

    if (scenario == 'react_inventory_records_products' && results.length == 1) {
      let result = results[0];
      let query = query.toLowerCase().trim();
      let inventory_record = result.inventory_records.find(
        (i) => i.serial_number.toLowerCase().trim() == query
      );
      if (inventory_record) {
        if (
          !window.react_inventory_records.state.products.find(
            (i) => i.id == result.id
          )
        ) {
          window.react_inventory_records.state.products.unshift(result);
        }

        if (
          !window.react_inventory_records.state.records.find(
            (i) => i.id == inventory_record.id
          )
        ) {
          window.react_inventory_records.state.records.unshift(
            inventory_record
          );
        }

        window.react_inventory_records.setState({
          products: window.react_inventory_records.state.products,
          records: window.react_inventory_records.state.records,
          focus: true,
        });

        setQuery('');
        setIndex(-1);
        input.value = '';

        setFastSelect(false);
        return true;
      }
    }
  };

  const handleSelect = (result, e) => {
    if (e) e.preventDefault();

    let row;
    switch (scenario) {
      case 'invoice_item':
        setQuery('');
        setIndex(-1);
        row = jQuery(input).closest('.nested-fields');
        row.find('input.code').val(result.code);
        row.find('input.ean').val(result.ean);
        row.find('input.name').val(result.name);
        row.find('input.product_id').val(result.id);
        row.find('select.account_id').val(result.account_id);
        row.find('input.value').val(result.price).trigger('change');
        break;
      case 'in_inventory':
        setQuery('');
        setIndex(-1);
        row = jQuery(input).closest('.nested-fields');
        row.find('input.code').val(result.code);
        row.find('input.ean').val(result.ean);
        row.find('input.name').val(result.name);
        row.find('input.product_id').val(result.id);
        if (result.purchase_price)
          row.find('input.price').val(result.purchase_price);
        break;
      case 'out_inventory':
        setQuery('');
        setIndex(-1);
        row = jQuery(input).closest('.nested-fields');
        row.find('input.code').val(result.code);
        row.find('input.ean').val(result.ean);
        row.find('input.name').val(result.name);
        row.find('input.id').val(result.id);
        break;
      case 'out_inventory_precise':
        setQuery('');
        setIndex(-1);
        row = jQuery(input).closest('.nested-fields');
        row.find('input.code').val(result.code).prop('readonly', true);
        row.find('input.ean').val(result.ean).prop('readonly', true);
        row.find('input.name').val(result.name).prop('readonly', true);
        row.find('input.amount').val(1).prop('readonly', true);
        row
          .find('input.serial_number')
          .val(result.serial_number)
          .prop('readonly', true);
        row.find('input.id').val(result.id);
        break;
      case 'react_inventory_records':
        window.react_inventory_records.state.records.unshift(result);
        window.react_inventory_records.setState({
          records: window.react_inventory_records.state.records,
        });
        if (result.type == 'InventoryRecord') {
          let products_helper =
            window.react_inventory_records.state.products.map((i) => {
              if (i.id == result.product_id) {
                if (i.amount > 1) {
                  i.amount -= 1;
                  return i;
                } else {
                  return null;
                }
              } else {
                return i;
              }
            });
          products_helper = products_helper.filter((i) => i != null);
          window.react_inventory_records.setState({
            products: products_helper,
            focus: true,
          });
        }
        setQuery('');
        setIndex(-1);
        input.value = '';
        break;
      case 'react_inventory_records_products':
        window.react_inventory_records.state.products.unshift(result);
        window.react_inventory_records.setState({
          products: window.react_inventory_records.state.products,
          focus: true,
        });
        setQuery('');
        setIndex(-1);
        input.value = '';
        break;
    }
  };

  const fetchResults = () => {
    if (query.length <= 2) return;
    if (currentRequestTimeout.current)
      clearTimeout(currentRequestTimeout.current);

    currentRequestTimeout.current = setTimeout(() => {
      const request = jQuery.ajax({
        method: 'GET',
        url: url,
        data: { q: query },
        beforeSend: () => {
          if (currentRequest.current) {
            currentRequest.current.abort();
          }
          currentRequest.current = null;
          setLoading(true);
        },
        success: function (data) {
          setResults(data.results);

          if (data.results.length && query) {
            let resultsHelper = [];

            if (window.react_inventory_records) {
              switch (scenario) {
                case 'react_inventory_records':
                  resultsHelper = data.results.filter(
                    (i) =>
                      !window.react_inventory_records.state.records.find(
                        (ii) => ii.id == i.id
                      )
                  );
                  break;
                case 'react_inventory_records_products':
                  resultsHelper = data.results.filter(
                    (i) =>
                      !window.react_inventory_records.state.products.find(
                        (ii) => ii.id == i.id
                      )
                  );
                  break;
              }

              let result = resultsHelper.find((i) => i.ean == query);
              if (result) handleSelect(result, null);
            }
          }
        },
        complete: () => {
          setLoading(false);
          currentRequest.current = null;
        },
      });

      currentRequest.current = request;
    }, 800);
  };

  let filteredResults = Object.assign([], results)
  if (window.react_inventory_records) {
    switch (scenario) {
      case 'react_inventory_records':
        filteredResults = filteredResults.filter(
          (i) =>
            !window.react_inventory_records.state.records.find(
              (ii) => ii.id == i.id
            )
        );
        break;
      case 'react_inventory_records_products':
        filteredResults = filteredResults.filter(
          (i) =>
            !window.react_inventory_records.state.products.find(
              (ii) => ii.id == i.id
            )
        );
        break;
    }
  }

  if (query.length <= 2) {
    return null;
  }

  return (
    <>
      <div className='whisper-reference' ref={refs.setReference}></div>
      <FloatingPortal>
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className='whisper'
          // className={classNames('whisper', {
          //   hidden: !(query.length > 2),
          // })}
        >
          {loading ? (
            <div className='loading'>
              <i className='fas fa-circle-notch fa-spin'></i>
            </div>
          ) : null}
          <table className='results-table'>
            <thead>
              <tr>
                <th>Obr.</th>
                <th>Kód</th>
                <th>EAN</th>
                <th>Název</th>
                {url.includes('inventory_records') ? null : <th>Skladem</th>}
                {url.includes('inventory_records') ? <th>S/N</th> : null}
              </tr>
            </thead>
            <tbody className='results'>
              {filteredResults.map((i, mapIndex) => {
                return (
                  <tr
                    key={i.id}
                    onClick={(e) => handleSelect(i, e)}
                    className={classNames('result', {
                      focused: mapIndex == index,
                    })}
                  >
                    <td className='image'>
                      {i.thumb_url ? (
                        <img src={i.thumb_url} alt={i.name} />
                      ) : null}
                    </td>
                    <td>{i.code}</td>
                    <td>{i.ean}</td>
                    <td>{i.name}</td>
                    {url.includes('inventory_records') ? null : (
                      <td>{i.inventory}</td>
                    )}
                    {url.includes('inventory_records') ? (
                      <td>{i.serial_number}</td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </FloatingPortal>
    </>
  );
}

export default Whisper;
